import { scrollTrigger } from '../../modules/scrollTrigger.js';
import { autoClamper } from '../../modules/autoClamper.js';
import { pageTop } from '../../modules/pageTop.js';
import { scrollReading } from '../../modules/scrollReading.js';
import { SimpleModal } from '../../modules/SimpleModal.js';
import { Splide } from 'npm:@splidejs/splide@4.1.4';


const mainSlider = document.querySelector('#main-slider');
if (mainSlider) {
	const slideElements = mainSlider.querySelectorAll('.splide__slide');
	const slideCount = slideElements.length;
	if (1 < slideCount) {
		const splide = new Splide('#main-slider', {
			width: '100%',
			perPage: 1,
			type: 'loop',
			padding: '0',
			focus: 'center',
			updateOnMove: true,
			mediaQuery: 'min',
			breakpoints: {
				1280: {
					fixedWidth: '1000px',
					perPage: 3
				}
			}
		});
		splide.mount();
	} else {
		mainSlider.classList.remove('splide');
	}
}

// おすすめセラピストのスライダー
const therapistSlider = document.querySelectorAll('.js-therapist-slider');
if (therapistSlider) {
	therapistSlider.forEach(el => {
		const slideElements = el.querySelectorAll('.splide__slide');
		const slideCount = slideElements.length;
		if (1 < slideCount) {
			const splide = new Splide('.js-therapist-slider', {
				type: 'loop',
				padding: '0',
				focus: 'center',
				updateOnMove: true,
				autoWidth: true,
				gap: '10px',
				mediaQuery: 'min',
				breakpoints: {
					1280: {
						destroy: true
					}
				}
			});
			splide.mount();
		} else {
			el.classList.remove('splide');
		}
	})
}

// セラピストの写真スライダー
const therapistPhotoSlider = document.querySelector('.js-therapist-profile-photo-slider');
if (therapistPhotoSlider) {
	const slideElements = therapistPhotoSlider.querySelectorAll('.splide__slide');
	const slideCount = slideElements.length;
	if (1 < slideCount) {
		const main_splide = new Splide('.js-therapist-profile-photo-slider', {
			type: 'loop',
			padding: '0',
			focus: 'center',
			updateOnMove: true,
			autoWidth: true,
			pagination : false,
			arrows     : true,
			gap: '10px',
			mediaQuery: 'min',
			breakpoints: {
				1280: {
					arrows: false
				}
			}
		});
		const thumbnails_splide = new Splide( '.js-therapist-profile-photo-thumbnail-slider', {
			rewind          : true,
			fixedWidth      : 50,
			fixedHeight     : 78,
			isNavigation    : true,
			gap             : 10,
			pagination      : false,
			cover: true,
			arrows     : false,
			dragMinThreshold: {
			  mouse: 4,
			  touch: 10,
			},
			breakpoints : {
			  640: {
				fixedWidth  : 66,
				fixedHeight : 38,
			  },
			},
		} );
		main_splide.sync(thumbnails_splide);
		
		main_splide.on( 'destroy', function () {
			// main_splide.options = { type: 'fade' };
			// main_splide.sync( thumbnails_splide );
			main_splide.refresh();
			thumbnails_splide.refresh();
		} );


		main_splide.mount();
		thumbnails_splide.mount();
	} else {
		therapistPhotoSlider.classList.remove('splide');
	}
}

// スクロールアニメ
new scrollTrigger('.js-scrl-fade-up')

// 複数行を丸める
new autoClamper('.js-auto-clamp')

// ページトップ
new pageTop()

// ajax+jsonによる追加読込
new scrollReading('.js-scrl-reading-point', { threshold: '0.12' })

// モーダル
new SimpleModal('.js-modal')